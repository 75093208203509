import { useState } from 'react'
import { AtlasConfigType, AtlasInitPageType, AtlasTracking } from '~/types/atlas'

const ATLAS_DEFAULT_CONFIG: AtlasConfigType = {
    system: {
        endpoint: 'astat.nikkei.com',
        cookieDomain: 'nikkei.com',
    },
    defaults: {
        pageUrl: window.parent.document.location.href,
        pageReferrer: window.parent.document.referrer,
        pageTitle: window.parent.document.title,
    },
    product: {
        productFamily: 'LissN',
        productName: 'LissN-Web',
    },
    options: {
        useGet: true,
        exchangeAtlasId: {
            pass: false,
            passParamKey: 'atlas_id',
            passTargetDomains: [],
            catch: false,
            catchParamKey: 'atlas_id',
            catchTargetDomains: [],
        },
        trackClick: {
            enable: true,
        },
        trackLink: {
            enable: true,
            internalDomains: ['www.nikkei.com', 'mw.nikkei.com', 'r.nikkei.com', 'id.nikkei.com'],
            nameAttribute: 'data-atlas-link-name',
        },
        trackDownload: {
            enable: true,
            fileExtensions: ['pdf', 'zip', 'laz', 'tar', 'gz', 'tgz', 'docx', 'xlsx', 'pptx', 'doc', 'xls', 'ppt'],
            nameAttribute: 'data-atlas-link-name',
        },
        trackPerformance: {
            enable: true,
        },
        trackScroll: {
            enable: true,
            granularity: 20,
            threshold: 2,
        },
        trackInfinityScroll: {
            enable: false,
            step: 600,
            threshold: 2,
        },
        trackRead: {
            enable: false,
            target: null,
            granularity: 25,
            milestones: [4, 15, 30, 60, 90, 120],
        },
        trackViewability: {
            enable: false,
            targets: [],
        },
        trackMedia: {
            enable: false,
            selector: 'video, audio',
            heartbeat: 5,
        },
        trackForm: {
            enable: false,
            target: null,
        },
        trackUnload: {
            enable: true,
        },
    },
}

const getAtlas = (): AtlasTracking | null => {
    const isAtlasLoad = window && 'querySelector' in window.parent.document && 'addEventListener' in window.parent
    if (isAtlasLoad) {
        // @ts-expect-error
        return window.atlasTracking as AtlasTracking
    } else {
        console.error('[ERROR] Atlas is not loaded.')
        return null
    }
}

/**
 * atlasのconfigを設定 & atlasTrackingを返す
 * @param {AtlasConfigType} params
 * @returns {AtlasTracking}
 */
const initAtlas = (params?: AtlasConfigType): AtlasTracking | undefined => {
    const atlas = getAtlas()
    if (atlas) {
        atlas.config({
            ...ATLAS_DEFAULT_CONFIG,
            ...params,
        })
        return atlas
    } else console.error('[ERROR] Atlas is not loaded.')
}

/**
 * atlasTrackingのtrackPageのみを実行
 */
const trackPage = () => {
    const atlas = getAtlas()
    if (!atlas) {
        console.error('[ERROR] Atlas is not loaded.')
        return
    }
    atlas.trackPage()
}

/**
 * atlasTrackingのinitPage & trackPageを実行
 * @param {AtlasInitPageType} params
 */
const track = (params?: AtlasInitPageType) => {
    const atlas = initAtlas()
    if (!atlas) {
        console.error('[ERROR] Atlas is not loaded.')
        return
    }

    atlas.initPage({
        user: {
            user_id: atlas.getCookieValue('NID-Serial-Cookie') || undefined,
            ...params?.user,
        },
        context: {
            category_l1: 'LissN',
            ...params?.context,
        },
    })
    atlas.trackPage()
}

/**
 * atlasTrackingのtrackActionを実行
 * @param {string} action
 * @param {string} category
 * @param {string | null | undefined} events
 * @param {unknown} obj
 */
const trackAction: AtlasTracking['trackAction'] = (
    action: string,
    category: string,
    events: string | null | undefined,
    obj: unknown,
) => {
    const atlas = initAtlas()
    if (!atlas) {
        console.error('[ERROR] Atlas is not loaded.')
        return
    }

    atlas.trackAction(action, category, events, obj)
}

export const useAtlas = () => {
    const [isLoad, setIsLoad] = useState(false)

    /**
     * 一度だけtrackを実行
     * @param {AtlasInitPageType} params
     */
    const trackOnce = (params?: AtlasInitPageType) => {
        if (!isLoad) {
            track(params)
            setIsLoad(true)
        }
    }

    /**
     * trackOnceを再実行できるようにする
     */
    const reset = () => {
        setIsLoad(false)
    }

    return {
        isLoad,
        setIsLoad,
        reset,
        trackOnce,
        track,
        trackPage,
        trackAction,
    }
}
