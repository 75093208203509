import { Link, Button } from '@chakra-ui/react'
import { FC, ReactElement } from 'react'
import { NsPropTypeBase } from '~/types/ns-prop-type-base'

export type CLpLinkButtonProps = {
    href?: string
    title?: string
    leftIcon?: ReactElement
    rightIcon?: ReactElement
    bgColor?: string
    color?: string
    hoverColor?: string
    onClick?: () => void
} & NsPropTypeBase

// logic
export const useCLpLinkButton = () => {
    return {}
}

// view
export const CLpLinkButtonView: FC<CLpLinkButtonProps & ReturnType<typeof useCLpLinkButton>> = (props) => {
    const {
        href,
        title,
        _style,
        rightIcon,
        leftIcon,
        color = 'white',
        hoverColor = '#87A5DF',
        bgColor = 'lpPrimary',
        onClick,
    } = props

    return (
        <Button
            data-testid="c-lp-link-button"
            bgColor={bgColor}
            as={Link}
            href={href}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            fontWeight={'semibold'}
            px={'1.5rem'}
            maxW={'30rem'}
            borderRadius={'0.5rem'}
            h={'3.5rem'}
            _hover={{ bg: hoverColor, textDecor: 'none' }}
            mx={'auto'}
            color={color}
            isExternal
            onClick={onClick}
            {..._style}>
            {title}
        </Button>
    )
}

// component
export const CLpLinkButton: FC<CLpLinkButtonProps> = (props: CLpLinkButtonProps) => {
    const hookItems = useCLpLinkButton()
    return <CLpLinkButtonView {...props} {...hookItems} />
}
